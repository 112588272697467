import Vue from 'vue'

const menu="dinningHall/mealtypedetail/";

export default {

    pagination(dtr,token){return Vue.prototype.$http.post(menu+"pagination",dtr);},
    save(obj,usr){
        return Vue.prototype.$http.post(menu+"save",obj,{    params:{usr:usr}});
    }
} 