<template>
  <div>
    <v-dialog v-model="dialog" v-if="dialog" persistent max-width="500">
      <v-card>
        <v-stepper v-model="e6" class="elevation-1">
          <v-stepper-header dense>
            <template v-for="n in steps">
              <v-stepper-step :key="`${n}-step`" :complete="e6 > n" :step="n">
                {{ MealsDetail[n - 1].TypeContentText }}
              </v-stepper-step>
              <v-divider v-if="n !== steps" :key="n"></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content 
              v-for="n in steps"
              :key="`${n}-content`"
              :step="n"
            >
              <v-col style="padding-top:0px;">
                <v-row
                  >
                  <v-col style="padding:0px;"> <s-text
                    autofocus
                    @keyupEnter="nextStep(n)"
                    label="Contenido"
                    v-model="MealsDetail[n - 1].MddDescription"
                  ></s-text></v-col>
                 </v-row
              ></v-col>
              <v-col
                ><v-row>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="
                      dialog = false;
                      e6 = 1;
                    "
                    >Cancelar</v-btn>
                  <v-btn color="primary" @click="nextStep(n)"> Siguiente</v-btn>
                </v-row></v-col
              >
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit"  v-if="dialogEdit" persistent max-width="500">
      <v-card> 
        <v-stepper v-model="e6" dense  light   >
          <v-stepper-header class="elevation-1" >
            <template v-for="n in stepsEdit" >
              <v-stepper-step :key="`${n}-step`" :complete="e6 > n" :step="n">
                {{ menuDaily.details[n - 1].TypeContentText }}
              </v-stepper-step>
              <v-divider v-if="n !== stepsEdit" :key="n"></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content style=''
              v-for="n in stepsEdit"
              :key="`${n}-content`"
              :step="n"
            >
              <v-col style='padding-top:0px;'>
                <v-row
                  >
                  <v-col style='padding:0px;'><s-text
                    autofocus
                    @keyupEnter="nextStepEdit(n)"
                    label="Contenido"
                    v-model="menuDaily.details[n - 1].MddDescription"
                  ></s-text></v-col></v-row
              ></v-col>
              <v-col
                ><v-row>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="
                      dialogEdit = false;
                      e6 = 1;
                    "
                    >Cancelar</v-btn
                  >
                  <v-btn color="primary" @click="nextStepEdit(n)">
                    Siguiente</v-btn
                  >
                </v-row></v-col
              >
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col lg="12">
        <s-crud
          noDark
          colorToolbar="white"
          ref="crudMenuDaily"
          :refreshTable="refreshDaily"
          @doubleClick="doubleclickcontent($event)"
          noFooter height='200'
          @save="save($event)" @removed=removed()
          noSearch
          @getDataRefresh="getMenuDaily($event)"
          :config="configDaily"
          :filter="filterDaily"
          noDoubleClick
          :heightTable="165"
          remove
          title="Programación"
        >
          <template scope="props">
            <div>
              <v-col>
                <v-row
                  ><v-col
                    ><s-text
                      autofocus
                      label="Extras"
                      @keyupEnter="editExtras()"
                      v-model="props.item.MndExtra"
                    ></s-text></v-col
                ></v-row>
              </v-col>
            </div>
          </template>
          <template v-slot:MndStatus="{ row }">
            <v-chip x-small="" v-if="row.MndStatus == 1" color="primary"
              >Pendiente
            </v-chip>
            <v-chip x-small="" v-if="row.MndStatus == 2" color="success">
              Aprobado</v-chip
            >
            <v-chip x-small="" v-if="row.MndStatus == 3" color="error"
              >Desaprobado
            </v-chip>
          </template>
        </s-crud>
        <v-toolbar
          v-if="observation"
          style="margin-top:1px;"
          height="35"
          rounded
          elevation="3"
          dense
        >
          <v-toolbar-title style="font-size:12px;color:var(--v-error-base)">
            Observación: {{ observation }}</v-toolbar-title
          >
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6" style="padding-top:0px;">
        <v-date-picker
          style="height:280px;"
          class="elevation-3"
          elevation="3"
          landscape
          dense
          v-model="date"
          locale="es"
          :events="functionEvents"
          full-width
        ></v-date-picker>
      </v-col>
      <v-col lg="6" style="padding-top:0px;">
        <s-crud ref="crudNoDaily"
          :refreshTable="refreshNoDaily"
          @doubleClick="doubleClickNoDaily($event)"
          :config="configNoDaily"
          :filter="filterNoDaily"
          noSearch
          noFooter
          :heightTable="225"
          title="Menús Disponibles"
        ></s-crud>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Service from "./../../services/DinningHall/MenuTypeService";
import MenuDailyNoSchedulingService from "./../../services/DinningHall/MenuDailyNoSchedulingService";
import MenuDailyService from "./../../services/DinningHall/MenuDailyService";
import MealTypeDetailService from "./../../services/DinningHall/MealTypeDetailService.";
import MenuTypeService from "./../../services/DinningHall/MenuTypeService";
export default {
  name: "MenuDaily",
  components: {},
  data: () => ({
    e6: 1,
    observation: "",
    dialog: false,
    MealsDetail: [],
    itemNoDaily: null,
    listHigherNow: [],
    refreshDaily: false,
    refreshNoDaily: false,
    arrayEvents: null,
    date: null,
    filterNoDaily: { MndDate: new Date().toISOString().substr(0, 10) },
    configNoDaily: {
      model: {
        MenuTypeText: "string",
        MealTypeText: "string",
        MntID: "int",
        MltID: "int",
        MlrID: "ID",
      },
      service: MenuDailyNoSchedulingService,
      headers: [
        { text: "Menú", value: "MealTypeText", sortable: false },
        { text: "Tipo de Plato", value: "MenuTypeText", sortable: false },
      ],
    },
    filterDaily: {
      MndDateBegin: new Date().toISOString().substr(0, 10),
      MndDateEnd: new Date().toISOString().substr(0, 10),
    },
    configDaily: {
      model: {
        MenuTypeText: "string",
        MealTypeText: "string",
        MntID: "int",
        MltID: "int",
        MndID: "ID",
        MndStatus: "status",
      },
      configStatus: [
        { text: "Aprobado", value: 2, color: "success" },
        { text: "Desaprobado", value: 3, color: "red" },
        { text: "Pendiente", value: 1, color: "info" },
      ],
      service: MenuDailyService,
      headers: [
        { text: "MndID", value: "MndID" },
        { text: "Tipo de Plato", value: "MealTypeText", sortable: false },
        { text: "Menú", value: "MenuTypeText", sortable: false },
        { text: "Contenido", value: "ContentText", sortable: false },
        { text: "Extras", value: "MndExtra", sortable: false },
        { text: "Estado", value: "MndStatus", sortable: false },
      ],
    },
    dialogEdit: false,
    menuDaily: null,
  }),
  watch: {
    date() {
      this.filterNoDaily = { MndDate: this.date };
      this.filterDaily = { MndDateBegin: this.date, MndDateEnd: this.date };
    },
  },
  computed: {
    steps() {
      return this.MealsDetail.length;
    },
    stepsEdit() {
      return this.menuDaily.details.length;
    },
  },
  methods: {
    removed(){
this.$refs.crudNoDaily.refresh();
    },
    editExtras() {
      this.$refs.crudMenuDaily.save();
    },
    getMenuDaily(items) {
    //  
      this.observation = "";
      if (items.length > 0) {
        let error = items.find((x) => x.MndStatus == 3);
        if (error != null) this.observation = error.MndObservation;
      }
    },
    save(item) {
      item.save();
    },
    nextStep(n) {
      if (this.MealsDetail[n - 1].MddDescription.trim().length <= 0) {
        this.$fun.alert("Es Obligatorio Ingresar este Campo", "warning");
        return;
      }
      this.MealsDetail[n - 1].MddDescription = this.MealsDetail[
        n - 1
      ].MddDescription.toUpperCase();
      if (n != this.MealsDetail.length) {
        this.e6 = n + 1;
      } else {
        if (this.guardando) return;
        this.dialog = false;
        this.guardando = true;

        let obj = {
          MltID: this.itemNoDaily.MltID,
          MntID: this.itemNoDaily.MntID,
          MndDate: this.date,
          details: this.MealsDetail,
        };

        MenuDailyService.save(obj, localStorage.getItem("UsrID")).then(() => {
          this.refreshNoDaily = !this.refreshNoDaily;
          this.refreshDaily = !this.refreshDaily;
          this.listHigherNow.push({ MndDate: this.date, MndStatus: 1 });
          this.e6 = 1;
          this.$nextTick().then(() => {
            this.guardando = false;
          });
        });
      }
    },
    nextStepEdit(n) {
      if (this.menuDaily.details[n - 1].MddDescription.trim().length <= 0) {
        this.$fun.alert("Es Obligatorio Ingresar este Campo", "warning");
        return;
      }
      this.menuDaily.details[n - 1].MddDescription = this.menuDaily.details[
        n - 1
      ].MddDescription.toUpperCase();
      if (n != this.menuDaily.details.length) {
        this.e6 = n + 1;
      } else {
        if (this.guardando) return;
        this.guardando = true;
        this.dialogEdit = false;
        MenuDailyService.save(
          this.menuDaily,
          localStorage.getItem("UsrID")
        ).then(() => {
          this.refreshDaily = !this.refreshDaily;
          this.e6 = 1;
          this.$nextTick().then(() => {
            this.guardando = false;
          });
        });
      }
    },
    doubleclickcontent(item) {
      MenuDailyService.buscaPorID(item.MndID).then((r) => {
        if (r.data.MndStatus != 2) this.dialogEdit = true;
        this.menuDaily = r.data;
      });
    },
    doubleClickNoDaily(item) {
      this.itemNoDaily = item;
      MealTypeDetailService.pagination({
        filter: { MltID: item.MltID },
        length: 3,
      }).then((r) => {
        if (r.data.data.length > 0) {
          this.MealsDetail = r.data.data;
          this.MealsDetail.forEach((element) => {
            element.MddDescription = "";
          });
          this.dialog = true;
        } else {
          let obj = {
            MltID: this.itemNoDaily.MltID,
            MntID: this.itemNoDaily.MntID,
            MndDate: this.date,
          };
          MenuDailyService.save(obj, localStorage.getItem("UsrID")).then(() => {
            this.refreshNoDaily = !this.refreshNoDaily;
            this.refreshDaily = !this.refreshDaily;
            this.listHigherNow.push({ MndDate: this.date, MndStatus: 1 });
            this.e6 = 1;
          });
        }
      });
    },  
    functionEvents(date) {
      console.log('XXXXXXXXXXXXXXXXXXXXXXXXXX');
      console.log(this.listHigherNow);
      let dates = this.listHigherNow.filter(
        (x) => this.$moment(x.MndDate).format("YYYY-MM-DD") == date
      );
      if (dates.length > 0) {
        let e = dates.filter((x) => x.MndStatus == 3); // SI HAY ALGUN MENU DESAPROBADO
        if (e.length > 0) return ["red"];
        else {
          let p = dates.filter((x) => x.MndStatus == 1); // SI HAY ALGUN MENU PROGRAMADO PRENDIENTE DE APROBACION
          if (p.length > 0) {
            return ["blue"];
          } else {
            return ["success"];
          }
        }
      }

      return false;
    },
    saveDef(item) {
      //  if(item.MntName.trim().length<1){ this.$store.getters.alertTop('Nombre Inválido','warning');return;};
      item.save();
    },
  },
  created() {
    this.date = this.$moment().format("YYYY-MM-DD");
    MenuDailyService.listHigherNow().then((r) => {
      this.listHigherNow = r.data;
    });
  },
};
</script>
